<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      responsive
      :items="quotes"
      :fields="computedFields"
    >
      <template #cell(folio)="data">
        <quote-button-route :quote="data.item" />
      </template>
      <template #cell(published_at)="data">
        {{ data.value | date }}
      </template>
      <template #cell(quote_status)="data">
        <b-badge :variant="$helpers.quoteStatusColor(data.value)">
          {{ $helpers.quoteStatusName(data.value) }}
        </b-badge>
      </template>
      <template #cell(is_express)="data">
        <b-badge
          :id="`tooltip-express-${data.item.id}`"
          pill
          :class="data.item.is_express ? 'bg-express' : 'bg-secondary'"
        >{{
          data.item.is_express
            ? $t('expressdelivery')
            : $t('ordinarydelivery')
        }}</b-badge>
      </template>
      <template #cell(payment_status)="data">
        <b-badge :variant="$helpers.paymentStatusColor(data.value)">
          {{ $helpers.paymentStatusName(data.value) }}
        </b-badge>
      </template>
      <template #cell(is_invoiced)="data">
        <div class="d-flex align-items-center">
          <b-badge
            :variant="data.value ? 'success' : 'secondary'"
            class="w-100"
          >
            {{ data.value ? $t('invoiced') : $t('pending') }}
          </b-badge>
          <b-button
            variant="primary"
            class="p-0 ml-1 custom-padding"
            @click="
              $router.push({
                name: 'quoteInvoices',
                params: { quoteId: data.item.id },
              })
            "
          >
            <feather-icon
              icon="EyeIcon"
              class="text-white"
              size="18"
              color="#000"
            />
          </b-button>
        </div>
      </template>
      <template #cell(total)="data">
        $ {{ data.value | money }}
      </template>
      <template
        v-if="!noTools"
        #cell(tools)="data"
      >
        <b-dropdown
          id="dropdown-1"
          size="sm"
          dropright
          no-caret
          variant="outline-none"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              color="#000"
            />
          </template>
          <b-dropdown-item
            v-b-modal.confirmDelete
            variant="danger"
            @click="quoteToDeleteId = data.item.id"
          >{{ $t('delete') }}</b-dropdown-item>
          <!-- <b-dropdown-item
            :to="{ name: 'editQuote', params: { quoteId: data.item.id } }"
          >{{ $t('edit') }}</b-dropdown-item>
          <b-dropdown-item
            :to="{
              name: 'addProductsToQuote',
              params: { quoteId: data.item.id },
            }"
          >{{ $t('add', { type: $t('products') }) }}</b-dropdown-item> -->
        </b-dropdown>
      </template>
    </b-table>
    <confirmation-modal
      :id="`confirmDelete`"
      :variant="'danger'"
      @confirm="removeQuote()"
    >
      <template v-slot:content>
        {{ $t('deleteQuoteConfirmation') }}
      </template>
    </confirmation-modal>
  </b-card>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import QuoteButtonRoute from '@/components/pricing/QuoteButtonRoute.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    BTable,
    QuoteButtonRoute,
    ConfirmationModal,
  },
  props: {
    quotes: {
      type: Array,
      default() {
        return []
      },
    },
    noTools: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'folio',
          label: 'Folio',
        },
        {
          key: 'published_at',
          label: this.$t('publishedDate'),
        },
        {
          key: 'is_express',
          label: this.$t('deliveryType'),
        },
        {
          key: 'quote_status',
          label: this.$t('status'),
        },
        {
          key: 'payment_status',
          label: this.$t('paymentStatus'),
        },
        {
          key: 'total',
          label: this.$t('total'),
        },
        {
          key: 'tools',
          label: this.$t('actions'),
        },
      ],
      quoteToDeleteId: null,
    }
  },

  computed: {
    computedFields() {
      const arr = [...this.fields]
      if (this.$route.name === 'quotes') {
        arr.splice(5, 0, {
          key: 'is_invoiced',
          label: this.$t('customerInvoice'),
        })
      } else if (this.$route.name === 'supplierQuotes') {
        // arr.splice(4, 0, {
        //   key: 'is_supplier_fully_invoiced',
        //   label: this.$t('supplierInvoice'),
        // })
      }

      return arr
    },
  },

  methods: {
    ...mapActions('quotes', ['deleteQuote', 'fetchQuotes']),

    removeQuote() {
      this.deleteQuote(this.quoteToDeleteId).then(() => {
        this.fetchQuotes({ by_project: this.$route.params.projectId })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-express {
  background-color: #120070;
}
</style>
