<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('myQuotes') }}
        </h1>
        <b-button
          variant="primary"
          @click="createQuote"
        >
          {{ $t('create', { type: $t('quote') }) }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <quotes-table :quotes="quotes" />
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-pagination
          :value="quotesPagination.page"
          :total-rows="quotesPagination.total_objects"
          :per-page="quotesPagination.per_page"
          aria-controls="my-table"
          @change="nextPage($event)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QuotesTable from '@/components/pricing/QuotesTable.vue'

export default {
  components: {
    QuotesTable,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters('quotes', ['quotes', 'quotesPagination']),
    ...mapGetters('projects', ['project']),
    ...mapGetters('auth', ['currentUser']),
  },

  created() {
    this.loading = true
    Promise.all([
      this.fetchQuotes({ by_project: this.$route.params.projectId }),
      this.fetchProject(this.$route.params.projectId),
    ]).then(() => {
      this.loading = false
    })
  },

  destroyed() {
    this.clearQuotes()
    // this.clearProject({})
  },

  methods: {
    ...mapMutations('quotes', {
      clearQuotes: 'CLEAR_QUOTES',
      changePagination: 'CHANGE_PAGINATION',
    }),
    ...mapMutations('projects', {
      clearProject: 'SET_PROJECT',
    }),
    ...mapActions('quotes', ['fetchQuotes', 'addQuote']),
    ...mapActions('projects', ['fetchProject']),

    nextPage(value) {
      this.changePagination({ field: 'page', value })
      this.loading = true
      this.fetchQuotes({
        by_project: this.$route.params.projectId,
        meta: { pagination: this.quotesPagination },
      }).then(() => {
        this.loading = false
      })
    },

    createQuote() {
      const quote = {}
      quote.address_attributes = { ...this.project.address }
      quote.project_id = this.$route.params.projectId
      quote.folio = Math.random() * (100 - 1) + 1
      this.addQuote({
        ...quote,
      }).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('quoteCreated'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$router.push({
          name: 'addProductsToQuote',
          params: { quoteId: response.id },
        })
      })
    },
  },
}
</script>

<style></style>
